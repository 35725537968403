import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import IsLoading from './assets/IsLoading'
import ReactNotifications from 'react-notifications-component';
import ProtectedRoute from './routes/ProtectedRoute';
import { lazy, Suspense } from 'react';
// import UserPortal from "./components/UserPortal"

// import Home from "./components/home/index"
import SwapTableContextProvider from './components/Contexts/SwapTableContext'
// import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import "antd/dist/antd.css";
import '../src/assets/css/index.css'



const  connect = lazy(() => import("./components/home/connect"))

const  UserPortal = lazy(() => import("./components/UserPortal"))







function App() {
	// const docs = [
	// 	// { uri: "https://edogoverp.com/documents/7585a327-a5a4-4a0e-9e96-3cd9203bb8b4_AGE.pdf" },
	// 	// { uri: require("./images/AGE.pdf") }, // Local File
	//   ];
  
const history = createBrowserHistory();
return (
  
  <SwapTableContextProvider>
  	<Router history={history}>
  	  <div className="app">
  	    <ReactNotifications />z
		  <Suspense fallback={<IsLoading show = {true}/>}>
  	    <Switch>
  	    
  	      <Route path="/" exact component={connect} />
			{/* <DocViewer  pluginRenderers={DocViewerRenderers} documents={docs} /> */}
  	      <ProtectedRoute path="/adminstore-portal" component={UserPortal} />
  	     <Route render={() => <h1>Error 404. Page not found.</h1>} />
  	    
  	    </Switch>
			</Suspense>
  	  </div>
  	</Router>
  </SwapTableContextProvider>
);

}


export default App;
