/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie'


const cookies = new Cookies()
const ProtectedRoute = ({ component: PureComponent, ...rest }) => {
  // const il = true
  const il = cookies.get('tokenExist') || false; // isLoggedin


  return (
    <Route
      {...rest}
      render={(props) => (il
        ? <PureComponent {...props} {...rest} /> : (
          <Redirect
            to={{
              pathname: '/', state: { from: props.location }
            }}
          />
        ))}
    />
  );
};

export default ProtectedRoute;
